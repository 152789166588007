var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("" + (JSON.parse(_vm.data.advertData.PageBanner.length && _vm.data.advertData.PageBanner[0].widgetItem) &&
    JSON.parse(_vm.data.advertData.PageBanner[0].widgetItem).widgetCode)),staticStyle:{"position":"relative","height":"100%"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":((_vm.data.advertData &&
      _vm.data.advertData.PageBanner.length &&
      _vm.data.advertData.PageBanner[0].advertContent) + "?x-oss-process=image/format,webp"),"alt":_vm.data.advertName}}),_vm._v(" "),_vm._l((_vm.data.advertData &&
    _vm.data.advertData.PageBanner.length &&
    _vm.data.advertData.PageBanner[0].buttonList),function(btnItem,index){return _c('div',{key:index,style:({
      position: 'absolute',
      left: ((btnItem.percentageLeft) + "%"),
      top: ((btnItem.percentageTop) + "%")
    })},[_c('GtButton',{style:({
        cursor: 'pointer',
        width: ((btnItem.width) + "px"),
        height: ((btnItem.height) + "px"),
        'line-height': ((btnItem.height) + "px"),
        'font-size': '12px'
      }),on:{"click":function($event){return _vm.handleGo(btnItem.link)}}},[_vm._v("\n      "+_vm._s(btnItem.text)+"\n    ")])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }