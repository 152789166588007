var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GtSwiper',{ref:"swiper",staticClass:"page-top-ad",style:({
    // height: noSetHeight ? 'unset' : isMobile ? 'auto' : '25vw'
    height: _vm.noSetHeight ? 'unset' : _vm.isMobile ? 'unset' : 'unset'
  }),attrs:{"data":[_vm.data[0]],"swiper-option":_vm.swiperOption},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var item = ref.data;
  var index = ref.index;
return _c('div',{},[_c('div',{staticClass:"slide-content-wrapper topAd_high"},[(item.bannerLink)?_c('a',{attrs:{"href":item.bannerLink,"target":"_blank"}},[_c('img',{attrs:{"src":item.advertContent + '?x-oss-process=image/format,webp',"alt":item.advertName}})]):_c('img',{attrs:{"src":item.advertContent + '?x-oss-process=image/format,webp',"alt":item.advertName}}),_vm._v(" "),_c('div',{class:item.btnClass},_vm._l((item.buttonList),function(btn,i){return _c('GtLink',{key:(index + "-" + i),staticClass:"sp-lp-img_btn_link",style:({
            top: ((btn.percentageTop) + "%"),
            left: ((btn.percentageLeft) + "%"),
            width: ((btn.btnPercentageWidth) + "%"),
            height: ((btn.btnPercentageHeight) + "%"),
            lineHeight: ((btn.btnPercentageHeight) + "%")
          }),attrs:{"to":btn.link,"target":"_blank"}},[_c('GtButton',{staticClass:"sp-lp-img_btn",class:("" + (JSON.parse(item.widgetItem)))},[_vm._v("\n            "+_vm._s(btn.text)+"\n          ")])],1)}),1)])])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }