export enum COUPON_STATUS {
  UNCLAIM = '01',
  UNUSED = '02',
  USED = '03',
  LOCKED = '04',
  EXPIRED = '05'
}

export enum COUPON_TYPE {
  IMMEDIATE_DISCOUNT = '01',
  DISCOUNT = '02',
  FREE_SHIPPING = '05',
  FREE_GIFT = '06',
  BY_ONE_GET_ONE_FREEE_07 = '07',
  BY_ONE_GET_ONE_FREEE_08 = '08',
  THRESHOLD_DISCOUNT = '12'
}

export default [
  {
    type: COUPON_TYPE.IMMEDIATE_DISCOUNT,
    name: '立减券'
  },
  {
    type: COUPON_TYPE.DISCOUNT,
    name: '折扣券'
  },
  {
    type: COUPON_TYPE.FREE_SHIPPING,
    name: '包邮券'
  },
  {
    type: COUPON_TYPE.FREE_GIFT,
    name: '赠品券'
  },
  {
    type: COUPON_TYPE.BY_ONE_GET_ONE_FREEE_07,
    name: '买赠券'
  },
  {
    type: COUPON_TYPE.BY_ONE_GET_ONE_FREEE_08,
    name: '买赠券'
  },
  {
    type: COUPON_TYPE.THRESHOLD_DISCOUNT,
    name: '满减券'
  }
];
