<template>
  <div v-if="isMobile" class="ua-mb-area">
    <GtSelect
      v-if="showSelect"
      v-model="region"
      suffix-icon="el-icon-arrow-up iconfont rotate-arrow"
      readonly="readonly"
      @focus="handleShowArea"
    />
    <Area
      ref="$area"
      :default-value="defaultValue"
      :columns-num="columnsNum"
      :use-custom-basic="$attrs['use-custom-basic']"
      :use-custom-parent-node="$attrs['use-custom-parent-node']"
      @confirmAddress="getAddress"
    />
  </div>
</template>

<script>
import { Input } from 'element-ui';
import Area from '@/components/Address/area.vue';

export default {
  components: {
    Area,
    GtSelect: Input
  },
  props: {
    showSelect: {
      type: Boolean,
      default: true
    },
    isMobile: {
      type: Boolean,
      default: true
    },
    changeValue: {
      type: Function
    },
    formData: {
      type: Object
    },
    editData: {
      type: Object
    },
    columnsNum: {
      type: Number
    },
    defaultValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      region: '',
      defaultRegion: []
    };
  },
  mounted() {
    setTimeout(async v => {
      await this.init();
    }, 100);
  },
  methods: {
    async init() {
      const ele = this.$refs.$area;
      console.log(this, 'addressPicker ---->>>>');
      if (this.editData) {
        const {
          stateCode = '',
          stateName = '',
          cityName = '',
          cityCode = '',
          districtCode = '',
          districtName = '',
          countryCode = '',
          countryName = '中国'
        } = this.editData;
        this.defaultRegion = [
          { code: countryCode, name: countryName },
          { code: stateCode, name: stateName },
          { code: cityCode, name: cityName },
          { code: districtCode, name: districtName }
        ];

        const list = [
          { code: stateCode, name: stateName },
          { code: cityCode, name: cityName },
          { code: districtCode, name: districtName }
        ].filter(v => !!v.name);
        this.region = list.map(v => v.name).join('/');

        if (this.region) {
          this.setFromRegionName();
        }

        if (ele) {
          ele.rootPCode = countryCode;
          ele.defaultProvince = stateCode;
          ele.defaultCity = cityCode;
          ele.defaultCounty = districtCode;
          await ele.init();

          this.getAddress(this.defaultRegion);
        }
      } else if (ele) {
        await ele.init();
      }
    },

    handleShowArea() {
      const ele = this.$refs.$area;
      if (ele) {
        ele.visible = true;
      }
    },

    setFromRegionName() {
      if (this.formData) {
        this.$set(this.formData, 'regionName', this.region);
      }
    },

    getAddress(val) {
      const [country = {}, ...others] = val || [];
      const [state = {}, city = {}, district = {}] = others;
      const name = others.map(v => v.name);

      this.region = name && name.filter(v => v) ? name.filter(v => v).join('/') : '';
      this.setFromRegionName();

      if (this.changeValue) {
        this.changeValue('country', this.processData(country));
        this.changeValue('state', this.processData(state));
        this.changeValue('city', this.processData(city));
        this.changeValue('district', this.processData(district));
      }

      this.$emit('confirm', others);
    },

    processData(address) {
      if (address.code) {
        const { code, name } = address;
        return {
          districtCode: code,
          districtName: name
        };
      } else {
        return {
          districtCode: '',
          districtName: ''
        };
      }
    }
  }
};
</script>

<style lang="scss">
.ua-mb-area {
  .rotate-arrow {
    transform: rotate(180deg);
  }

  .el-select {
    width: 100%;

    .el-input {
      width: 100%;
    }
  }

  .van-popup__close-icon--top-right {
    top: 14px;
    right: 16px;
    font-size: 16px;
  }
}
</style>
