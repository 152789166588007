var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('section',{staticClass:"new-nav-mobile-container"},[_c('div',{staticClass:"new-nav-mobile-container__left"},[_vm._l((_vm.data),function(item,index){return [_c('div',{key:index,staticClass:"new-nav-mobile-container__left-item",class:{ active: _vm.chooseIndex == index },on:{"click":function($event){return _vm.changFn(item, index)}}},[_vm._v("\n          "+_vm._s(item.navigationTitle)+"\n        ")])]})],2),_vm._v(" "),_c('div',{staticClass:"new-nav-mobile-container__right",staticStyle:{"overflow-y":"auto"}},[(_vm.data[_vm.chooseIndex] && _vm.data[_vm.chooseIndex].children)?[_vm._l((_vm.data[_vm.chooseIndex].children),function(item,index){return [_c('div',{key:index,staticClass:"new-nav-mobile-container__right-item"},[(index > 0 || _vm.data[_vm.chooseIndex].styleId === '8')?_c('section',[_c('div',{staticClass:"new-nav-mobile-container__right-item__header"},[_c('span',{style:({ color: JSON.parse(item.extendParams).color })},[_vm._v("\n                  "+_vm._s(item.navigationTitle)+"\n                ")]),_vm._v(" "),(item.navigationUrl)?_c('nuxt-link',{attrs:{"to":{ path: item.navigationUrl || '' }}},[(['2', '6'].includes(item.styleId) || _vm.data[_vm.chooseIndex].styleId === '8')?_c('div',{staticClass:"new-nav-mobile-container__right-item__header-all"},[_c('span',{style:({
                        cursor: 'pointer',
                        color: JSON.parse(item.extendParams).color
                      })},[_vm._v("\n                      全部\n                    ")]),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/ua-icon24/icon-arrow-right-small.svg"),"alt":"icon-arrow-right-small"}})]):_vm._e()]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"new-nav-mobile-container__right-item__content"},[(item.children && item.children.length)?[(item.styleId !== '5')?_c('div',[_vm._l((item.children),function(item2,index2){return [_c('div',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
                          click_type: '悬浮',
                          navigation_primary_category: item2.navigationTitle
                        }),expression:"{\n                          click_type: '悬浮',\n                          navigation_primary_category: item2.navigationTitle\n                        }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
                          click_type: '点击',
                          navigation_primary_category: item2.navigationTitle
                        }),expression:"{\n                          click_type: '点击',\n                          navigation_primary_category: item2.navigationTitle\n                        }",arg:"selectType"}],key:index2,class:{
                          'new-nav-mobile-container__right-item__content-item':
                            item.styleId === '2' || item.styleId === '4',
                          'new-nav-mobile-container__right-item__content-sport':
                            item.styleId === '4',
                          'new-nav-mobile-container__right-item__content-brand':
                            item.styleId === '3',
                          'new-nav-mobile-container__right-item__content-sport-type':
                            item.styleId === '6',
                          'new-nav-mobile-container__right-item__content-no-name':
                            item.styleId === '7' || _vm.data[_vm.chooseIndex].styleId === '8',
                          'item-2_width': item.children.length === 2 && item.styleId === '2'
                        }},[_c('nuxt-link',{attrs:{"to":{ path: item2.navigationUrl || '' }}},[(item.styleId === '7' || _vm.data[_vm.chooseIndex].styleId === '8')?_c('img',{attrs:{"src":((item2.navigationImageList.length &&
                              item2.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp"),"alt":item2.navigationTitle}}):_c('GtImage',{attrs:{"src":((item.styleId === '2' || item.styleId === '4'
                                ? item2.navigationIcon
                                : item2.navigationImageList.length &&
                                  item2.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp"),"alt":item2.navigationTitle}}),_vm._v(" "),(
                              item.styleId !== '3' &&
                              item.styleId !== '7' &&
                              _vm.data[_vm.chooseIndex].styleId !== '8'
                            )?_c('p',[_vm._v("\n                            "+_vm._s(item2.navigationTitle)+"\n                          ")]):_vm._e()],1)],1)]})],2):_vm._e(),_vm._v(" "),(item.styleId === '5')?_c('div',[_c('GtSwiper',{ref:"swiper",refInFor:true,staticClass:"nav-brand-swiper",attrs:{"data":item.brandList || [],"swiper-option":_vm.swiperOption},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var data = ref.data;
return _c('div',{ref:"swiperDom",refInFor:true,staticClass:"swiper-detail"},_vm._l((data),function(item2,index2){return _c('div',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
                            click_type: '悬浮',
                            navigation_primary_category: item2.navigationTitle
                          }),expression:"{\n                            click_type: '悬浮',\n                            navigation_primary_category: item2.navigationTitle\n                          }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
                            click_type: '点击',
                            navigation_primary_category: item2.navigationTitle
                          }),expression:"{\n                            click_type: '点击',\n                            navigation_primary_category: item2.navigationTitle\n                          }",arg:"selectType"}],key:index2,staticClass:"swiper-detail__item"},[_c('nuxt-link',{attrs:{"to":{
                              path: item2.navigationUrl || ''
                            }}},[_c('GtImage',{attrs:{"src":((item2.navigationImageList.length &&
                                item2.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp"),"alt":item2.navigationTitle}})],1)],1)}),0)}}],null,true)},[_vm._v(" "),_c('div',{staticStyle:{"display":"flex","justify-content":"center","height":"20px"},attrs:{"slot":"pagination"},slot:"pagination"},[_c('div',{staticClass:"swiper-pagination"})])]),_vm._v(" "),(item.advertList)?_c('section',{key:index,staticClass:"style5-advert_mobile"},_vm._l((item.advertList),function(advertItem){return _c('div',{key:advertItem.advertCode,staticClass:"style5-advert_mobile_item"},[(advertItem.advertData)?[_c('SeriesAdert',{staticStyle:{"margin-bottom":"8px"},attrs:{"data":advertItem}})]:_vm._e()],2)}),0):_vm._e()],1):_vm._e()]:_vm._e()],2)]):_vm._e()])]})]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }