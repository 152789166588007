<template>
  <div v-if="hotWords.length">
    <div class="hot-word-search__header">
      <p
        :style="{
          'font-size': isMobile ? '14px' : '14px'
        }"
      >
        热门搜索
      </p>
      <div
        v-if="isMobile ? hotWords.length > 6 : hotWords.length > 5"
        class="qiehuan-box"
        @click="changeList"
      >
        <span>换一换</span>
        <img :src="require(`@/assets/image/qiehuan-change.svg`)" alt="qiehuan-change" />
      </div>
    </div>
    <div class="word">
      <nuxt-link
        v-for="(item, index) in showList"
        :key="index"
        v-click-track="{
          eventName: 'researchButtonClick',
          data: {
            reseach_button: '顶部搜索',
            key_word: item.hotword,
            key_word_type: '热门搜索'
          },
          extraCacheData: {
            key_word_type: '热门搜索'
          },
          beforeTrack: () => {
            $trackHelp.setUserActiveData('key_word_list', arr => [...arr, item.hotword]);
          }
        }"
        :to="item.linkUrl"
      >
        <div class="word-box">
          <GtImage
            v-if="item.imageUrl"
            style="width: 48px"
            :src="`${item.imageUrl}?x-oss-process=image/format,webp`"
            :alt="item.hotword"
            aspect-ratio="121.75%"
          />
          <span
            :class="{
              'hotword-no-img-width': !item.imageUrl
            }"
          >
            {{ item.hotword }}
          </span>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { useHome } from '@speedshop/sdk-api';
import { GtImage } from '@speedshop/template';
import { mapGetters } from 'vuex';
import { queryHotwords } from '@/graphql/login.gql';
export default {
  name: 'HeaderSearch',
  components: {
    GtImage
  },
  data() {
    return {
      useHome: null,
      // 热词推荐
      hotWords: [],
      showList: [],
      showIndex: 0,
      mathFlag: false
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin'])
  },
  async mounted() {
    await this.handleHotWord();
    if (!this.isMobile) {
      this.showList = this.hotWords.slice(0, 5);
    } else {
      this.showList = this.hotWords.slice(0, 6);
    }
  },
  methods: {
    goPage(item) {
      if (item.linkUrl.includes('https:') || item.linkUrl.includes('http:')) {
        window.location.href = item.linkUrl + '?isHotKey=true&searchPos=Search-Top';
      } else {
        const link = item.linkUrl
          ? `${
              item.linkUrl.includes('?')
                ? item.linkUrl + '&isHotKey=true&searchPos=Search-Top'
                : item.linkUrl + '?isHotKey=true&searchPos=Search-Top'
            }`
          : `/search?searchWord=${item.hotword}&isHotKey=true&searchPos=Search-Top`;
        this.$router.push(link);
      }
    },
    async handleHotWord() {
      this.useHome = useHome();
      const {
        data: {
          data: { hotwordsOutputs }
        }
      } = await this.useHome.gqlClient.query(queryHotwords);
      this.hotWords = hotwordsOutputs;
    },
    async changeList() {
      // 换一换逻辑第一次进来 每次截取5个 最后一批不足5个从开头补几个
      // 最后一批展示完切换查接口拿5个（借口做了随机的功能）
      if (!this.mathFlag) {
        const arr = !this.isMobile
          ? [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
          : [6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114];
        if (arr[this.showIndex] > this.hotWords.length) {
          this.showIndex = 0;
        }
        this.showList = this.hotWords.slice(arr[this.showIndex], arr[this.showIndex + 1]);
        this.showIndex++;
        if (this.showList.length < (!this.isMobile ? 5 : 6)) {
          const arr = this.hotWords.slice(0, (!this.isMobile ? 5 : 6) - this.showList.length);
          this.showList = [...this.showList, ...arr];
          this.mathFlag = true;
        }
      } else {
        const {
          data: {
            data: { hotwordsOutputs }
          }
        } = await this.useHome.gqlClient.query(queryHotwords, {
          sortType: 2
        });
        this.showList = hotwordsOutputs.slice(0, !this.isMobile ? 5 : 6);
      }
    }
  }
};
</script>

<style lang="scss">
@mixin liner {
  @keyframes in {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes out {
    from {
      background-color: var(--main-color-ua-dark-grey, #14141e);
      left: 0;
    }
    to {
      left: 100%;
    }
  }
  span {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    overflow-x: hidden;
    padding-bottom: 2px;
    line-height: 20px;
    &::after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      animation: out 0.5s ease;
      animation-play-state: running;
      animation-iteration-count: 1;
      visibility: hidden;
      transition: all 0ms 1s;
    }
    // &:hover {
    //   &::after {
    //     background-color: var(--main-color-ua-dark-grey, #14141e);
    //     animation: in 0.5s ease;
    //     animation-iteration-count: 1;
    //     visibility: visible;
    //     transition: all 0.5s;
    //   }
    // }
    &.active {
      border-bottom: 1px solid var(--main-color-ua-dark-grey, #14141e);
    }
  }
}
.word {
  padding-bottom: 48px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-top: 16px;
  gap: 8px;
  a {
    width: calc((100% - 32px) / 5);
  }
  .word-box {
    background: var(--main-color-ua-light-grey, #f0f0f0);
    display: flex;
    height: 60px;
    min-width: 120px;
    padding: 0px 12px 0px 8px;
    align-items: center;
    gap: 8px;
    .gt-image-container {
      background-color: transparent !important;
      width: 58px;
      height: 100%;
    }
    span {
      width: calc(100% - 28px - 48px);
      font-size: 12px;
      font-weight: 600;
      max-height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  @include liner;
}
.hot-word-search {
  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    p {
      color: var(--main-color-ua-dark-grey, #14141e);
      font-size: 28px;
      font-weight: 600;
    }
    .qiehuan-box {
      cursor: pointer;
      display: flex;
      span {
        line-height: 14px;
        color: var(--main-color-ua-dark-grey, #14141e);
        font-size: 14px;
        font-weight: 400;
        margin-right: 8px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .word {
    gap: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 12px 0 28px 0;
    a {
      width: calc((100% - 8px) / 2);
    }
    .word-box {
      background: var(--main-color-ua-light-grey, #f0f0f0);
      display: flex;
      height: 60px;
      padding: 0px 12px 0px 8px;
      align-items: center;
      gap: 8px;
      .gt-image-container {
        background-color: transparent !important;
        width: 58px;
        height: 100%;
      }
      span {
        width: calc(100% - 28px - 48px);
        font-size: 12px;
        font-weight: 600;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  .hot-word-search {
    margin-bottom: 32px;
    &__header {
      p {
        font-size: 14px;
      }
      .qiehuan-box {
        span {
          font-size: 12px;
        }
      }
    }
  }
}
.hotword-no-img-width {
  width: 100% !important;
  text-align: center !important;
}
</style>
