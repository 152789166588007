import { render, staticRenderFns } from "./links.vue?vue&type=template&id=634f478c&scoped=true&"
import script from "./links.vue?vue&type=script&lang=ts&"
export * from "./links.vue?vue&type=script&lang=ts&"
import style0 from "./links.vue?vue&type=style&index=0&id=634f478c&lang=scss&scoped=true&"
import style1 from "./links.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634f478c",
  null
  
)

export default component.exports