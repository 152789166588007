var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gt-nav-mobile-children"},[_c('i',{staticClass:"iconfont icon-arrow-left",staticStyle:{"position":"fixed","top":"19px","left":"24px"},on:{"click":_vm.closeChildren}},[_vm._v("\n    全部\n  ")]),_vm._v(" "),_c('GtCollapse',{attrs:{"value":_vm.activeNames}},[_c('div',{staticClass:"gt-nav-mobile-children__title"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('span',{on:{"click":function($event){return _vm.$router.push({ path: _vm.childrenUrl })}}},[_vm._v("查看全部")])]),_vm._v(" "),_vm._l((_vm.data),function(item,i){return [(item.children && item.children.length > 0 && item.level === 3)?_c('div',{key:item.navigationCode,class:{
          'el-collapse-item__header': true,
          'is-mb-active': _vm.active(item) || item.navigationUrl === _vm.$route.path
        }},[_c('ul',{staticClass:"nav-item-wrapper"},[_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.goPage(item.navigationUrl, item.navigationName, item.isUrl)}}},[_c('span',[_vm._v(_vm._s(item.navigationTitle))]),_vm._v(" "),(_vm.isMobile)?_c('i',{staticClass:"gt-nav__icon iconfont icon-a-24-1px-right-s"}):_vm._e()])])]):(item.children && item.children.length > 0 && item.level != 3)?_c('GtCollapseItem',{key:((item.navigationCode) + "--2--" + i),class:{
          'is-mb-active': _vm.active(item) || item.navigationUrl === _vm.$route.path
        },attrs:{"title":item.navigationName,"name":item.navigationName}},[_c('section',{class:{
            'mb-title-title': true,
            'mb-title-active': _vm.active(item) || item.navigationUrl === _vm.$route.path
          },attrs:{"slot":"title"},slot:"title"},[[_c('span',{style:({ color: JSON.parse(item.extendParams).color }),on:{"click":function($event){return _vm.goPage(item.navigationUrl, item.navigationName, item.isUrl, true)}}},[_vm._v("\n              "+_vm._s(item.navigationTitle)+"\n            ")])]],2),_vm._v(" "),(item.children && item.children.length > 0)?_c('ul',_vm._l((item.children),function(c){return _c('li',{key:c.navigationCode,class:{
              'sub-item': true,
              current: c.navigationUrl === _vm.$route.path
            },on:{"click":function($event){return _vm.goPage(c.navigationUrl, item.navigationName, item.isUrl)}}},[(c.navigationIcon != '')?_c('img',{staticClass:"nav-icon",attrs:{"src":c.navigationIcon,"alt":"icon"}}):_vm._e(),_vm._v(" "),_c('div',[_c('span',{style:({ color: JSON.parse(c.extendParams).color })},[_vm._v("\n                "+_vm._s(c.navigationTitle)+"\n              ")])])])}),0):_vm._e()]):_c('div',{key:item.navigationCode,class:{
          'el-collapse-item__header': true,
          'is-mb-active': _vm.active(item) || item.navigationUrl === _vm.$route.path
        }},[_c('ul',[_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.goPage(item.navigationUrl, item.navigationName, item.isUrl)}}},[_c('span',{style:({ color: JSON.parse(item.extendParams).color })},[_vm._v("\n              "+_vm._s(item.navigationTitle)+"\n            ")])])])])]})],2),_vm._v(" "),_c('div',{staticClass:"gt-nav-mobile-drawer__bottom"},[_vm._t("bottom")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }