import couponTypeMap from './coupon-enum';

export const getCouponType = coupon => {
  return (
    couponTypeMap.find(i => i.type === coupon.rewardType) || {
      isUndefined: true,
      type: coupon.rewardType,
      name: '优惠券'
    }
  );
};

export const getCouponShortDesc = coupon => {
  return coupon.activityShortDesc?.replace(/<\/?[^>]+(>|$)/g, '');
};
