<template>
  <div ref="mobileLishi" class="search-history">
    <div ref="historyWdith" class="history-content">
      <div
        v-for="(item, index) in historyData"
        :key="index"
        class="history-item"
        @click="notifyParent(item)"
      >
        {{ item }}
      </div>
      <button v-if="showHistoryBtnFlag" class="history-item toggle-button" @click="toggleExpand">
        <img v-if="isExpanded" src="@/assets/ua-icon24/icon-arrow-up-small.svg" alt="icon-up" />
        <img v-else src="@/assets/ua-icon24/icon-arrow-down-small.svg" alt="icon-down" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ExpandCollapse',
  components: {},
  props: {
    historyData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isExpanded: false,
      showHistoryBtnFlag: false
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin'])
  },
  mounted() {
    this.$nextTick(() => {
      this.calcItemsToShow();
      this.showHistoryBtnFn();
      this.toggleExpand();
      this.toggleExpand();
    });
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      const dom = document.querySelectorAll('.history-item.collapse-item');
      if (dom.length) {
        // 展开collapse-item
        if (this.isExpanded) {
          dom.forEach(item => {
            item.style.display = 'inline-block';
          });
          // 展开时 收起的最后一个dom 展开时也显示出来
          const dom2 = document.querySelectorAll('.history-item:not([class*=" "])');
          dom2[dom2.length - 1].style.display = 'inline-block';
          this.displayItem();
          this.isDisplayLastChild();
        } else {
          // 收起 collapse-item
          dom.forEach(item => {
            item.style.display = 'none';
          });
          this.isDisplayLastChild('hidden');

          const dom2 = document.querySelectorAll('.history-item:not([class*=" "])');
          dom2[dom2.length - 1].style.display = 'none';
        }
      }
    },

    calcItemsToShow() {
      const dom = document.querySelectorAll('.history-item:not([class*=" "])');
      let firstData = 0;
      const cssArr = [];
      if (dom.length) {
        // 拿所有dom offsetTop值
        dom.forEach(item => {
          cssArr.push(item.offsetTop);
        });
        // 拿到比第一个大的 offsetTop 的值 （这个肯定就是第二行的值）
        firstData = cssArr.find(item => {
          return item > cssArr[0];
        });
        // 给第二行后面的dom添加class
        dom.forEach(item => {
          if (item.offsetTop > firstData) {
            item.classList.add('collapse-item');
          }
        });
      }
    },
    displayItem() {
      // 超出4行全部隐藏
      const dom = document.querySelectorAll('.history-item:not(.toggle-button)');
      const parentDom = document.querySelector('.history-content');
      if (dom.length) {
        dom.forEach((item, index) => {
          if (item?.getBoundingClientRect().top - parentDom?.getBoundingClientRect().top >= 192) {
            dom[index - 1].classList.add('expand-item');
            item.style.display = 'none';
          }
        });
      }
    },
    isDisplayLastChild(flag = '') {
      // 所有展示的dom  收起是hidden  反之是展开
      const dom =
        flag === 'hidden'
          ? document.querySelectorAll('.history-item:not([class*=" "])')
          : document.querySelectorAll('.history-item.expand-item');
      const parentDom = document.querySelector('.history-content');
      // 判断 收起时最后一个dom距离父元素右边的距离
      // 判断 展开时第一个dom距离父元素右边的距离
      if (
        parentDom.offsetWidth -
          dom[flag === 'hidden' ? dom.length - 1 : 0]?.getBoundingClientRect().left -
          80 <
        30
      ) {
        dom[flag === 'hidden' ? dom.length - 1 : 0].style.display = 'none';
      }
    },
    showHistoryBtnFn() {
      if (this.isMobile) {
        const ref = this.$refs.mobileLishi;
        if (ref) {
          console.log(ref.offsetHeight, 'zxcadjjas');
          this.showHistoryBtnFlag = ref.offsetHeight > 125;
        }
      }
    },
    notifyParent(item) {
      this.$emit('search', item);
    }
  }
};
</script>

<style lang="scss">
.search-history {
  width: 100%;
}

.history-content {
  margin-top: 16px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 8px;
  min-width: 80px;
  overflow: hidden;
}

.history-item,
.toggle-button {
  text-align: center;
  cursor: pointer;
  background: var(--main-color-ua-light-grey, #f0f0f0);
  height: 40px;
  line-height: 25px;
  min-width: 80px;
  padding: 6px;
  color: var(--main-color-ua-dark-grey, #14141e);
  font-size: 12px;
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toggle-button {
  width: 30px;
  min-width: 30px;
  text-align: center;
  border: none;
  background: none;
  display: flex;
  align-items: end;
}
</style>
