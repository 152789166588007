<template>
  <div>
    <GtDrawer
      v-if="!isMobile"
      direction="rtl"
      size="480px"
      :visible.sync="drawerFlag"
      class="ua-shipping-bag__drawer ua-shipping-modify__drawer coupon-rule-drawer brand-story-drawer"
      :append-to-body="true"
      :modal-append-to-body="false"
    >
      <div
        slot="title"
        class="coupon-rule-drawer__title"
        style="display: flex; justify-content: space-between; align-items: center"
      >
        <p style="font-size: 24px; font-weight: 600">品牌故事</p>
        <span class="close_animation" @click="drawerFlag = false"></span>
      </div>

      <div class="brand-story__content">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <section @click="handleGo(item)">
              <img :src="item.url" :alt="item.name" />
              <!-- <span>{{ item.name }}</span> -->
            </section>
          </li>
        </ul>
      </div>
    </GtDrawer>

    <van-popup v-else v-model="drawerFlag" position="bottom" class="coupon-rule">
      <div class="coupon-rule-title">
        <p>品牌故事</p>
        <img src="@/assets/ua-icon24/icon-close.svg" alt="icon-close" @click="drawerFlag = false" />
      </div>
      <div class="brand-story__content">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <img :src="item.url" :alt="item.name" />
            <!-- <span>{{ item.name }}</span> -->
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GtDrawer } from '@speedshop/template';

export default {
  name: 'BarndStory',
  components: { GtDrawer },
  data() {
    return {
      drawerFlag: false,
      list: [
        {
          name: '关于品牌',
          url: require('@/assets/image/brandstory/关于品牌.png'),
          to: '/brand/aboutua.htm'
        },
        {
          name: '运动明星',
          url: require('@/assets/image/brandstory/运动明星.png'),
          to: '/brand/athletes.htm'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    handleGo(item) {
      this.$router.push(item.to);
      this.drawerFlag = false;
    }
  }
};
</script>

<style lang="scss">
.brand-story {
  &__content {
    padding: 28px 40px 0;
    li {
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;
      span {
        position: absolute;
        left: 20px;
        bottom: 16px;
        color: var(--main-color-ua-white, #fff);
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
@keyframes brandAnimation {
  from {
    transform: translateX(0);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}
.brand-story-drawer {
  .el-drawer__open .el-drawer.ltr {
    animation: brandAnimation 1s ease;
  }
  .el-drawer__close-btn {
    display: none;
  }
}

@keyframes closeAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
}

@keyframes closeAnimation2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-45deg);
  }
}

.close_animation {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.close_animation::before,
.close_animation::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: rgba(20, 20, 30, 1);
}

.close_animation::before {
  animation: closeAnimation 1s ease;
  animation-fill-mode: forwards;
}

.close_animation::after {
  animation: closeAnimation2 1s ease;
  animation-fill-mode: forwards;
}
</style>
