<template>
  <GtSwiper
    ref="swiper"
    :data="[data[0]]"
    :swiper-option="swiperOption"
    class="page-top-ad"
    :style="{
      // height: noSetHeight ? 'unset' : isMobile ? 'auto' : '25vw'
      height: noSetHeight ? 'unset' : isMobile ? 'unset' : 'unset'
    }"
  >
    <div slot="default" slot-scope="{ data: item, index }">
      <div class="slide-content-wrapper topAd_high">
        <a v-if="item.bannerLink" :href="item.bannerLink" target="_blank">
          <img
            :src="item.advertContent + '?x-oss-process=image/format,webp'"
            :alt="item.advertName"
          />
        </a>
        <img
          v-else
          :src="item.advertContent + '?x-oss-process=image/format,webp'"
          :alt="item.advertName"
        />
        <div :class="item.btnClass">
          <GtLink
            v-for="(btn, i) in item.buttonList"
            :key="`${index}-${i}`"
            :to="btn.link"
            class="sp-lp-img_btn_link"
            :style="{
              top: `${btn.percentageTop}%`,
              left: `${btn.percentageLeft}%`,
              width: `${btn.btnPercentageWidth}%`,
              height: `${btn.btnPercentageHeight}%`,
              lineHeight: `${btn.btnPercentageHeight}%`
            }"
            target="_blank"
          >
            <GtButton class="sp-lp-img_btn" :class="`${JSON.parse(item.widgetItem)}`">
              {{ btn.text }}
            </GtButton>
          </GtLink>
        </div>
      </div>
    </div>
  </GtSwiper>
</template>

<script>
import { GtLink, GtButton, componentsCommon, GtSwiper, GtImage } from '@speedshop/template';
export default {
  components: { GtImage, GtSwiper, GtButton, GtLink },
  mixins: [componentsCommon],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    noSetHeight: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {};
  },
  computed: {
    swiperOption() {
      return {
        autoplay: false,
        loop: true
        //  pagination:this.data.length>1?{
        //     el: '.advertisement-swiper-pagination',
        //     clickable: true //允许分页点击跳转
        //   }:false
      };
    }
  },
  watch: {
    data: {
      handler(val, old) {
        val.forEach(item => {
          if ('widgetItem' in item) {
            item.btnClass = JSON.parse(item.widgetItem) && JSON.parse(item.widgetItem).widgetCode;
          } else {
            item.btnClass = 'advert-btn-white';
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.page-top-ad {
  overflow-x: hidden;
  .swiper-slide > div {
    height: 100%;
  }
  &.sp-swiper-container {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .sp-lp-img_btn_link {
      position: absolute;
      .sp-lp-img_btn {
        width: 100% !important;
        height: 100% !important;
        line-height: 1.1 !important;
      }
    }
  }
}
.topAd_high {
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.advert-btn-white {
  button {
    background: #fff !important;
    border-color: #fff !important;
  }
}
.advert-btn-black {
  button {
    color: #fff !important;
    background: #000 !important;
    border-color: #000 !important;
  }
}
</style>
