var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'ua-coupon-select',
    { 'is-focus': _vm.isFocus },
    { 'offset-placeholder': _vm.offsetPlaceholder },
    { 'is-disabled': _vm.disabled },
    { 'is-nonselectable': !_vm.hasSelectable }
  ]},[_c('div',{staticClass:"ua-coupon-select__input-wrapper",on:{"click":_vm.onClick}},[(_vm.labelable)?_c('div',{staticClass:"ua-coupon-select__placeholder"},[_vm._v("\n      "+_vm._s(_vm.offsetPlaceholder ? _vm.label : _vm.placeholder)+"\n    ")]):_vm._e(),_vm._v(" "),_c('Input',{staticClass:"ua-coupon-select__input",attrs:{"value":_vm.couponStrs,"placeholder":_vm.labelable ? undefined : _vm.placeholder,"readonly":""}}),_vm._v(" "),_c('div',{staticClass:"ua-coupon-select__dropdown-icon__wrapper"},[_c('div',{staticClass:"ua-coupon-select__dropdown-icon"},[(_vm.dropdownVisible)?_c('div',{staticClass:"iconfont icon-a-16-1px-up-s"}):_c('div',{staticClass:"iconfont icon-a-16-1px-down-s"})])])],1),_vm._v(" "),_c('Dropdown',{ref:"dropdownRef",staticClass:"ua-coupon-select__dropdown",attrs:{"placement":"bottom-start","trigger":"click","disabled":_vm.disabled},on:{"visible-change":_vm.onDropdownVisible},scopedSlots:_vm._u([{key:"dropdown",fn:function(){return [_c('DropdownMenu',{staticClass:"ua-coupon-select__dropdown-menu",attrs:{"slot":"dropdown","visible-arrow":false,"append-to-body":false,"offset":-1},slot:"dropdown"},[_c('div',{staticClass:"ua-coupon-select__dropdown-menu-inner",style:({ width: _vm.triggerWidth + 'px' })},[_vm._l((_vm.couponList),function(coupon){return [_c('div',{key:coupon.couponCode,class:[
                'ua-coupon-select__dropdown-menu-item',
                { 'is-checked': Boolean(_vm.checkIndex(coupon) + 1) }
              ],on:{"click":function($event){_vm.onCheck(_vm.checkIndex(coupon), coupon)}}},[_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__content"},[_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__title text-overflow"},[_vm._v("\n                  "+_vm._s(coupon.activityName)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__subtitle"},[_vm._v("\n                  "+_vm._s(_vm.dayjs(coupon.validStartTime).format('YYYY.MM.DD HH:mm'))+"-"+_vm._s(_vm.dayjs(coupon.validEndTime).format('YYYY.MM.DD HH:mm'))+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__right"},[_c('Checkbox',{attrs:{"value":Boolean(_vm.checkIndex(coupon) + 1)}})],1)])]}),_vm._v(" "),(_vm.disabledCouponList.length)?[_c('Divider'),_vm._v(" "),_c('div',{staticClass:"ua-coupon-select__dropdown-menu__label"},[_vm._v("以下优惠券不可用：")]),_vm._v(" "),_vm._l((_vm.disabledCouponList),function(coupon){return [_c('div',{key:coupon.couponCode,class:['ua-coupon-select__dropdown-menu-item', { 'is-disabled': true }],on:{"click":function($event){_vm.onCheck(_vm.checkIndex(coupon), coupon)}}},[_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__content"},[_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__title text-overflow"},[_vm._v("\n                    "+_vm._s(coupon.activityName)+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-select__dropdown-menu-item__subtitle"},[_vm._v("\n                    "+_vm._s(_vm.dayjs(coupon.validStartTime).format('YYYY.MM.DD HH:mm'))+"-"+_vm._s(_vm.dayjs(coupon.validEndTime).format('YYYY.MM.DD HH:mm'))+"\n                  ")])])])]})]:_vm._e()],2)])]},proxy:true}])},[_c('div',{staticClass:"ua-coupon-select__dropdown-trigger"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }