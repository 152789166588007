<template>
  <div :class="['ua-coupon-item', { 'is-small': isSmall }, { 'is-disabled': disabled }]">
    <div class="ua-coupon-item__main">
      <div class="ua-coupon-item__left">
        <div class="ua-coupon-item__left-inner">
          <div
            v-if="!couponType.isUndefined"
            :class="[
              'ua-coupon-item__price-wrapper',
              `ua-coupon-item__price__${couponData.rewardType}`
            ]"
          >
            <span
              v-if="
                [
                  COUPON_TYPE.IMMEDIATE_DISCOUNT,
                  COUPON_TYPE.DISCOUNT,
                  COUPON_TYPE.THRESHOLD_DISCOUNT
                ].includes(couponData.rewardType)
              "
              class="ua-coupon-item__price"
            >
              {{ parseFloat(couponData.faceValue) }}
            </span>
          </div>
          <div class="ua-coupon-item__type">
            {{ couponType.name }}
          </div>
          <div v-if="couponData.memberLevelDsc" class="ua-coupon-item__member-tag__warpper">
            <div class="ua-coupon-item__member-tag">
              {{ couponData.memberLevelDsc }}
            </div>
          </div>
        </div>
      </div>
      <div class="ua-coupon-item__content">
        <div class="ua-coupon-item__content__inner">
          <div class="ua-coupon-item__title text-overflow">{{ couponData.activityName }}</div>
          <div class="ua-coupon-item__subtitle">
            <div v-if="activityShortDescText" class="ua-coupon-item__desc text-overflow">
              {{ activityShortDescText }}
            </div>
            <div v-if="rule" class="ua-coupon-item__show-rule" @click.stop="onShowRule">
              使用规则
            </div>
          </div>
          <div class="ua-coupon-item__expire text-overflow">
            <template v-if="couponData.isStart">
              有效期至{{ dayjs(couponData.validEndTime).format('YYYY.MM.DD HH:mm') }}
            </template>
            <template v-else>
              生效时间{{ dayjs(couponData.validStartTime).format('YYYY.MM.DD HH:mm') }}
            </template>
          </div>
        </div>
      </div>
      <div class="ua-coupon-item__dot-wrapper">
        <template v-for="(dot, index) in dots">
          <div :key="index" class="ua-coupon-item__dot"></div>
        </template>
      </div>
    </div>
    <div class="ua-coupon-item__side" @click="onClick">
      <div v-if="!disabled && couponData.expired" class="ua-coupon-item__about-to-expired">
        <div class="ua-coupon-item__about-to-expired__text">快过期</div>
      </div>
      <template v-if="mode === 'claim'">
        <div v-if="claim" class="ua-coupon-item__click-trigger">
          <div class="ua-coupon-item__side-text">已领取</div>
        </div>
        <div v-else class="ua-coupon-item__click-trigger cursor-pointer" @click="onClaim">
          <div class="ua-coupon-item__side-text">立即领取</div>
        </div>
      </template>
      <div
        v-else-if="couponData.status === COUPON_STATUS.EXPIRED"
        class="ua-coupon-item__click-trigger"
      >
        <div class="ua-coupon-item__side-text">已过期</div>
      </div>
      <div
        v-else-if="couponData.status === COUPON_STATUS.USED"
        class="ua-coupon-item__click-trigger"
        @click="onClick"
      >
        <div class="ua-coupon-item__side-text">已使用</div>
      </div>
      <div v-else-if="!couponData.isStart" class="ua-coupon-item__click-trigger">
        <div class="ua-coupon-item__side-text">未开始</div>
      </div>
      <div v-else class="ua-coupon-item__click-trigger cursor-pointer" @click="onUse">
        <div class="ua-coupon-item__side-text">立即使用</div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentsCommon } from '@speedshop/template';
import dayjs from 'dayjs';
import { getCouponType, getCouponShortDesc } from './coupon-tool';
import { COUPON_STATUS, COUPON_TYPE } from './coupon-enum';

export default {
  name: 'CouponItem',
  mixins: [componentsCommon],
  props: {
    couponData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    /**
     * @description: default | claim
     * @return {*}
     */
    mode: {
      type: String,
      default: 'default'
    },
    small: {
      type: Boolean,
      default: false
    },
    claim: {
      type: Boolean,
      default: false
    },
    rule: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      COUPON_STATUS,
      COUPON_TYPE,
      dayjs
    };
  },
  /**
   * 已过期: expired
   * 已使用: used
   * 立即领取: available
   * 未开始: pending
   * 立即使用: spendable
   */
  computed: {
    isSmall() {
      return this.isMobile || this.small;
    },
    dots() {
      return new Array(this.isSmall ? 12 : 8).fill('');
    },
    couponType() {
      return getCouponType(this.couponData);
    },
    activityShortDescText() {
      return getCouponShortDesc(this.couponData);
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    onClaim() {
      this.$emit('claim');
    },
    onUse() {
      this.$emit('use');
    },
    onShowRule() {
      this.$emit('showRule');
    }
  }
};
</script>

<style lang="scss" scoped>
.ua-coupon-item {
  --main-color: var(--coupon-theme);
  height: 100px;
  display: flex;
  flex-direction: row;
  // padding: 4px 4px 4px 0px;
  border-radius: 1px;
  border: 6px solid transparent;
  border-right: none;
  background-color: var(--main-color);
  background-clip: border-box;
  &.is-small {
    height: 80px;
    border-width: 4px;
    .ua-coupon-item__dot-wrapper {
      left: -2px;
    }
    .ua-coupon-item__dot {
      width: 4px;
      height: 4px;
    }
    .ua-coupon-item__content {
      padding: 8px 0;
    }
    .ua-coupon-item__content__inner {
      padding: 0 16px;
    }
    .ua-coupon-item__title {
      font-size: 14px;
      line-height: 20px;
      height: 20px;
    }
    .ua-coupon-item__left {
      flex-basis: 72px;
    }
    .ua-coupon-item__side {
      flex-basis: 72px;
      margin: -4px 0;
    }
    .ua-coupon-item__side-text {
      font-size: 12px;
      line-height: 18px;
    }
  }
  &.is-disabled {
    --main-color: #f0f0f0;
    .ua-coupon-item__price-wrapper {
      color: var(--secondary-color-ua-cool-grey-03);
    }
    .ua-coupon-item__side {
      background-color: var(--main-color);
      color: var(--secondary-color-ua-cool-grey-03);
    }
    .ua-coupon-item__right {
      display: none;
    }
    .ua-coupon-item__content__inner {
      border-right: none;
      border-left: 1px dashed #ddd;
    }
    .ua-coupon-item__title {
      color: var(--secondary-color-ua-cool-grey-03);
    }
    .ua-coupon-item__subtitle {
      color: var(--secondary-color-ua-cool-grey-03);
    }
    .ua-coupon-item__show-rule {
      color: var(--secondary-color-ua-cool-grey-03);
    }
  }
}
.ua-coupon-item__main {
  min-width: 0;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 1px;
}
.ua-coupon-item__left {
  flex-basis: 80px;
  flex-shrink: 0;
  flex-grow: auto;
  color: #50505a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ua-coupon-item__left-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.ua-coupon-item__content {
  min-width: 0;
  flex-grow: 1;
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.ua-coupon-item__content__inner {
  min-width: 0;
  flex-basis: 100%;
  padding: 0 20px;
  border-left: 1px dashed #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ua-coupon-item__title {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: var(--main-color-ua-dark-grey);
}
.ua-coupon-item__subtitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--secondary-color-ua-cool-grey-03);
}
.ua-coupon-item__desc {
  margin-right: 4px;
}
.ua-coupon-item__show-rule {
  flex-shrink: 0;
  color: var(--main-color-ua-dark-grey);
  text-decoration: underline;
  // text-underline-position: under;
  text-underline-offset: 2px;
  cursor: pointer;
}
.ua-coupon-item__expire {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--secondary-color-ua-cool-grey-03);
}
.ua-coupon-item__side {
  flex-basis: 88px;
  flex-shrink: 0;
  flex-grow: 0;
  color: #fff;
  border-radius: 1px;
  margin: -6px 0;
  position: relative;
  overflow: hidden;
}
.ua-coupon-item__about-to-expired {
  display: flex;
  align-items: flex-end;
  height: 32px;
  padding: 0 20px;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #14141e;
  transform-origin: center top;
  transform: translateX(-50%) rotate(45deg);
  white-space: nowrap;
}
.ua-coupon-item__about-to-expired__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.ua-coupon-item__click-trigger {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ua-coupon-item__side-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.ua-coupon-item__dot-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.ua-coupon-item__dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--main-color);
}
.ua-coupon-item__price-wrapper {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: var(--main-color);
  margin-bottom: 6px;
  &::after {
    content: '优惠';
    font-size: 20px;
  }
}
.ua-coupon-item__price {
  font-family: 'Neue Plak Black' !important;
}
.ua-coupon-item__price__01,
.ua-coupon-item__price__12 {
  align-items: flex-start;
  font-size: 32px;
  line-height: 23px;
  height: 23px;
  &::before {
    content: '¥';
    font-family: 'Neue Plak Black' !important;
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    margin-right: 2px;
  }
  &::after {
    content: none;
  }
}
.ua-coupon-item__price__02 {
  align-items: baseline;
  font-size: 36px;
  line-height: 25px;
  height: 25px;
  &::after {
    content: '折';
    font-size: 14px;
    font-weight: 800;
    line-height: 14px;
    margin-left: 2px;
  }
}
.ua-coupon-item__price__05 {
  &::after {
    content: '包邮';
  }
}
.ua-coupon-item__price__06,
.ua-coupon-item__price__07,
.ua-coupon-item__price__08 {
  &::after {
    content: '赠品';
  }
}
.ua-coupon-item__type {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.ua-coupon-item__member-tag__warpper {
  --tag-size: 16px;
  position: relative;
  height: var(--tag-size);
}
.ua-coupon-item__member-tag {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  height: var(--tag-size);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #fff;
  background: linear-gradient(180deg, #000000, #670100);
  padding: 0px 4px;
  border-radius: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
