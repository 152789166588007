<template>
  <div
    :class="['ua-checkbox', { 'is-checked': value }, { 'is-disabled': disabled }]"
    @click="onClick"
  >
    <span class="ua-checkbox__icon iconfont icon-a-16-1px-SUCEESS"></span>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit('update:value', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.ua-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 1px;
  border: 1px solid var(--main-color-ua-dark-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
  user-select: none;
  cursor: pointer;
  &.is-checked {
    background-color: var(--main-color-ua-dark-grey);
    .ua-checkbox__icon {
      display: block;
      transform: rotate(0deg) scaleY(1);
    }
  }
  &.is-disabled {
    background-color: var(--btn-disabled-background-color);
    border-color: var(--line-color-line-grey-01);
    .ua-checkbox__icon {
      color: var(--line-color-line-grey-01);
    }
  }
}
.ua-checkbox__icon {
  display: none;
  font-size: 12px;
  color: #fff;
  transform: rotate(45deg) scaleY(0);
  transition: transform 0.15s ease-in 0.05s;
}
</style>
