var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['ua-coupon-option', { 'is-disabled': _vm.disabled }],on:{"click":_vm.onClick}},[_c('div',{staticClass:"ua-coupon-option__side"},[_c('div',{staticClass:"ua-coupon-option__side-inner"},[(!_vm.couponType.isUndefined)?_c('div',{class:[
          'ua-coupon-option__price-wrapper',
          ("ua-coupon-option__price__" + (_vm.couponData.rewardType))
        ]},[(
            [
              _vm.COUPON_TYPE.IMMEDIATE_DISCOUNT,
              _vm.COUPON_TYPE.DISCOUNT,
              _vm.COUPON_TYPE.THRESHOLD_DISCOUNT
            ].includes(_vm.couponData.rewardType)
          )?_c('span',{staticClass:"ua-coupon-option__price"},[_vm._v("\n          "+_vm._s(parseFloat(_vm.couponData.faceValue))+"\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__type"},[_vm._v("\n        "+_vm._s(_vm.couponType.name)+"\n      ")]),_vm._v(" "),(_vm.couponData.memberLevelDsc)?_c('div',{staticClass:"ua-coupon-option__member-tag__warpper"},[_c('div',{staticClass:"ua-coupon-option__member-tag"},[_vm._v("\n          "+_vm._s(_vm.couponData.memberLevelDsc)+"\n        ")])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__main"},[_c('div',{staticClass:"ua-coupon-option__content"},[_c('div',{staticClass:"ua-coupon-option__content__inner"},[_c('div',{staticClass:"ua-coupon-option__title text-overflow"},[_vm._v(_vm._s(_vm.couponData.activityName))]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__subtitle"},[(_vm.activityShortDescText)?_c('div',{staticClass:"ua-coupon-option__desc text-overflow"},[_vm._v("\n            "+_vm._s(_vm.activityShortDescText)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__show-rule",on:{"click":function($event){$event.stopPropagation();return _vm.onShowRule.apply(null, arguments)}}},[_vm._v("使用规则")])]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__expire text-overflow"},[_vm._v("\n          有效期至"+_vm._s(_vm.dayjs(_vm.couponData.validEndTime).format('YYYY.MM.DD HH:mm'))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__right"},[_c('Checkbox',{attrs:{"value":_vm.check}})],1)]),_vm._v(" "),_c('div',{staticClass:"ua-coupon-option__dot-wrapper"},[_vm._l((_vm.dots),function(dot,index){return [_c('div',{key:index,staticClass:"ua-coupon-option__dot"})]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }